body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.small {
  font-size: 80%;
}

.playerBg {
  background-color: rgb(0,0,0,0.1) !important;
  font: 15px Tahoma !important;
  color: white !important;
}

.videoPlayer {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #FFFFFF;
}

:focus {
  outline: none;
  font-size: 140%;
  font-weight: bold;
  color: #df6e74 !important;
}

.fs-big {
  font-size: 120% !important;
}

.m-b-xl {
  margin-bottom: 2em !important;
}

.m-b {
  margin-bottom: 0.5em !important;
}

.m-xl {
  margin: 2em !important;
}

.m-xs {
  margin: 0.2em !important;
}

.m-t {
  margin: 0.3em;
}

.d-flex {
  display: flex;
}
.flex-1 {
  flex: 1 1 auto;
}

.v-wrapper {
  /* max-width: 54%; */
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  margin-bottom: 1rem !important;
}
.v-wrapper:focus {
  margin-bottom : 0.93rem !important
}

.sky-blue {
  background: linear-gradient(#26e6fd,#26edac);
}
.red {
  background: linear-gradient(#df6e74,#a35b5f);
}
.green {
  background: linear-gradient(#87eacb,#4aa78a);
}
.purple {
  background: linear-gradient(#856ad1,#4d3d88);
}

.v-wrapper:focus img  {
  transform: scale(1.1);
  box-shadow: 0px 0px 0px 3px #FFFFFF;
  color: white !important;
}

.v-wrapper:focus .videoName-wrapper {
  transform: scale(1.1);
  padding: 0.5em;
  bottom: 0.9rem;
  border-radius: 3px;
}

.v-wrapper:focus {
  margin-right: 0.2em !important;
}

.ui.breadcrumb .active.section {
  color: #2185d0!important;
}

.video-link {
  color: #2185d0!important;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: underline;
}

.video-link.active {
  color: #ffffff!important;
  cursor: none;
  text-decoration: none;
}

.p-xl {
  padding: 2em !important;
}

.p-0 {
  padding: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.videoName-wrapper {
  position : absolute;
  bottom : 0px;
  padding : 1em 2em;
  object-fit: contain;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #000000);
  color : white;
  width: calc(100% - 1rem);
  right: 0.5rem;
  left: 0.5rem;
  bottom: 1rem;
}

.videoName-wrapper .videoName {
  object-fit: contain;
  font-family: XFINITYStandard;
  font-size: 26.9px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  white-space : nowrap;
  overflow : hidden;
  text-overflow : ellipsis;
  text-align : center; 
}

#cameras .videoName-wrapper {
  bottom: -0.25rem;
  font-size: 45%;
}
/* margin-bottom: 0rem !important;
} */

.video-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}