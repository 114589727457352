

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.blk-100 {
  display: block;
  width: 100%;
}

.cameratitle {
  margin-top: -1em !important;
  margin-bottom: 0.5em !important;
}

.announcementIcon{
  display: inline-block;
  opacity: 1;
  margin: 0 .25rem 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
}

.noMessage {
  padding: 2em !important;
}