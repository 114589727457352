
.error-wrapper{
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.msg-wrapper {
    width: 569px !important;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #b4b4b4;
    padding-bottom: 29px;
}

.by-1 {
    border-width : 1px 0px 1px 0px;
    border-color : grey;
    border-style : solid;   
    padding-top: 10px;
    padding-bottom: 10px;
}

.b-0 {
    border : 0px !important; 
}
#errorOkBtn {
    font-size: 34px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2b9cd8;
    margin-top: 14px;
    padding-left:10px;
}
#errorOkBtn:focus {
    border-top: 3px solid #fff!important;
    padding: 8px;
    border-radius: 3px;
    font-weight: 700;
    color: #2185d0!important;
    border-bottom: 3px solid #fff!important;
}

h2.header.blue.msg-header {
    font-size: 42px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.67;
    letter-spacing: normal;
    text-align: left;
    color: #2b9cd8;
    padding-bottom: 33px;
}